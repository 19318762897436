import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/css/styles.css";
import "./assets/js/scripts.js";
import "bootstrap/dist/css/bootstrap.min.css";


createApp(App).use(router).mount("#app");
