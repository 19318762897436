<template>
  <!-- Masthead-->

  <header class="masthead">
    <div class="container position-relative">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <div class="text-center text-white">
            <!-- Page heading-->
            <h1 class="mb-5">Buy Discounted Airtime.</h1>
            <h1 class="mb-5">On All Major Providers!</h1>
            <form
              class="form-horizontal"
              action=""
              method="post"
              enctype="multipart/form-data"
            >
              <div class="form-group row mb-3">
                <label class="col-md-4 col-form-label" for="text-input"
                  >Amount</label
                >
                <div class="col-md-6">
                  <input
                    class="form-control"
                    id="text-input"
                    type="text"
                    name="text-input"
                    placeholder="Text"
                  />
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-md-4 col-form-label" for="select1"
                  >Provider</label
                >
                <div class="col-md-6">
                  <select class="form-control" id="select1" name="select1">
                    <option>Tap to Select</option>
                    <option>Safaricom</option>
                    <option>Airtel</option>
                    <option>Telkom</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-md-4 col-form-label" for="select1"
                  >Number Paying (MPESA)</label
                >
                <div class="col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    name="text-input"
                    placeholder="Text"
                  />
                </div>
              </div>
              <button class="btn btn-sm btn-warning mb-3" type="submit">
                Buy
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Icons Grid-->

  <div class="text-center">
    <div class="container">
      <BecomeASeller />
    </div>
  </div>
  <div class="features-icons-icon d-flex">
    <i class="bi-window m-auto text-primary"></i>
  </div>
  <section class="features-icons bg-light text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <i class="bi-window m-auto text-primary"></i>
            </div>
            <h3>Your airtime remains with you until sold</h3>
            <p class="lead mb-0">
              We do not transfer the airtime to ourselves!
            </p>
            <p class="lead mb-0">
              Airtime is transferred to the purchaser whenever a buy happens.
            </p>
            <p class="lead mb-0">
              And money transferred to your wallet immediately!
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <i class="bi-layers m-auto text-primary"></i>
            </div>
            <h3>Sharable Link</h3>
            <p class="lead mb-0">
              You can get a sharable link to your airtime listing and share with
              potential customers.
            </p>
            <p class="lead mb-0">
              You can generate the link once you have successfully indicated the
              amount you want to sell.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <i class="bi-terminal m-auto text-primary"></i>
            </div>
            <h3>Unlimited Sales</h3>
            <p class="lead mb-0">
              You can make unlimited sales per day each handled with speed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container text-center">
    <h3>Costs</h3>
    <p class="lead mb-0">
      To facilitate sales operations, we take 5% of the nominal value of the
      airtime amount sold.
    </p>
  </div>
  <!-- Image Showcases-->
  <section class="showcase">
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div
          class="col-lg-6 order-lg-2 text-white showcase-img"
          :style="{
            'background-image':
              'url(' + require('../assets/img/bg-showcase-1.jpg'),
          }"
        ></div>
        <div class="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>Transparent Transactions</h2>
          <p class="lead mb-0">
            You can view your transactions on the mobile app!
          </p>
        </div>
      </div>
      <div class="row g-0">
        <div
          class="col-lg-6 text-white showcase-img"
          :style="{
            'background-image':
              'url(' + require('../assets/img/bg-showcase-2.jpg'),
          }"
        ></div>
        <div class="col-lg-6 my-auto showcase-text">
          <h2>Increase or reduce sale amount</h2>
          <p class="lead mb-0">
            Change the amount available for sale for each provider at any time!
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonials-->
  <section class="testimonials text-center bg-light">
    <div class="container">
      <h2 class="mb-5">What people are saying...</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="testimonial-item mx-auto mb-5 mb-lg-0">
            <img
              class="img-fluid rounded-circle mb-3"
              src="../assets/img/testimonials-1.jpg"
              alt="..."
            />
            <h5>Liz Kamau</h5>
            <p class="font-weight-light mb-0">
              "This is fantastic! Discounted airtime anytime!"
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="testimonial-item mx-auto mb-5 mb-lg-0">
            <img
              class="img-fluid rounded-circle mb-3"
              src="../assets/img/testimonials-2.jpg"
              alt="..."
            />
            <h5>Isaac Matero</h5>
            <p class="font-weight-light mb-0">"Quick buy"</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="testimonial-item mx-auto mb-5 mb-lg-0">
            <img
              class="img-fluid rounded-circle mb-3"
              src="../assets/img/testimonials-3.jpg"
              alt="..."
            />
            <h5>Sarah Kerubo</h5>
            <p class="font-weight-light mb-0">"Cheap airtime"</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Call to Action-->
  <footer class="footer bg-dark">
    <div class="container justify-content-center">
      <div class="row">
        <p class="text-muted small mb-4 mb-lg-0">
          ©Cellular 2021. All Rights Reserved.
        </p>
        <p class="text-muted small mb-4 mb-lg-0">A Product of Cybervant Ltd</p>
        <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
          <ul class="list-inline mb-0">
            <li class="list-inline-item me-4">
              <a href="#!"><i class="bi-facebook fs-3"></i></a>
            </li>
            <li class="list-inline-item me-4">
              <a href="#!"><i class="bi-twitter fs-3"></i></a>
            </li>
            <li class="list-inline-item">
              <a href="#!"><i class="bi-instagram fs-3"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import BecomeASeller from "@/components/BecomeASeller.vue";

export default {
  components: { BecomeASeller },
};
</script>
